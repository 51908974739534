<template>
  <bg-modal
    :value="isOpen"
    title="Yakin menghapus biaya tambahan?"
    desktop-size="md"
    class="additional-cost-delete-modal"
    data-testid="additional-cost-delete-modal"
    @close="closeModal"
  >
    <bg-text
      size="body-2"
      v-html="modalDescription"
      data-testid="modal-description"
    />
    <template v-if="isListingLevel">
      <bg-text size="title-5" class="bg-u-mt-xl">Nama Biaya</bg-text>
      <bg-text
        size="body-2"
        class="bg-u-mt-xxs"
        data-testid="fee-name"
        >{{ data.feeName }}</bg-text
      >

      <bg-text size="title-5" class="bg-u-mt-xl">Listing</bg-text>
      <bg-text
        size="body-2"
        class="bg-u-mt-xxs"
        data-testid="listing-name"
        >{{ data.name }}</bg-text
      >

      <bg-text size="title-5" class="bg-u-mt-xl">Harga</bg-text>
      <bg-text
        v-if="data.pricingMethod === 'defined_by_billing'"
        size="body-2"
        class="bg-u-mt-xxs"
        data-testid="listing-prices"
      >
        Disesuaikan dengan tagihan
      </bg-text>
      <template v-else>
        <bg-text
          v-for="(price, index) in priceListFormatted"
          :key="`price-list-${index}`"
          size="body-2"
          class="bg-u-mt-xxs"
          data-testid="listing-prices"
          >{{ price }}</bg-text
        >
      </template>
    </template>

    <template #footer>
      <div class="flex justify-end">
        <bg-button size="lg" class="mr-16" @click="closeModal"
          >Kembali</bg-button
        >
        <bg-button
          size="lg"
          variant="primary"
          :loading="isLoading"
          @click="deleteItem"
          >Hapus</bg-button
        >
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { currencyFormatter } from 'Utils/formatter';
import { BgModal, BgButton, BgText } from 'bangul-vue';
import { endpoints } from '@admin_endpoints/additional-fee';

const TIME_FORMAT = {
  daily: 'Harian',
  weekly: 'Mingguan',
  monthly: 'Bulanan',
};
const FEE_FORMAT = {
  kg: 'kg',
  person: 'orang',
  item: 'item/pcs',
};

export default {
  name: 'DeleteConfirmModal',

  components: {
    BgModal,
    BgButton,
    BgText,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    level: {
      type: String,
      default: 'general',
      validator: level => ['general', 'listing'].indexOf(level) >= 0,
    },
    data: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    propertyId() {
      return this.$route.params.propertyId;
    },
    isListingLevel() {
      return this.level === 'listing';
    },
    modalDescription() {
      return this.isListingLevel
        ? 'Biaya ini akan terhapus dari listing yang telah diterapkan dan tidak dapat dikembalikan.'
        : `Biaya <b>${this.data.name}</b> akan terhapus dari properti serta listing yang telah diterapkan dan tidak dapat dikembalikan.`;
    },
    priceListFormatted() {
      const priceList = this.data.amounts.map(item => {
        const { fee_unit, time_unit, amount } = item;
        const price = currencyFormatter(amount);
        const priceTimeUnit = TIME_FORMAT[time_unit];
        const priceFeeUnit = FEE_FORMAT[fee_unit];

        return `Rp${price} / ${priceTimeUnit || priceFeeUnit} ${
          priceTimeUnit && priceFeeUnit ? `(Per ${priceFeeUnit})` : ''
        }`;
      });

      return priceList;
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
    },

    deleteItem() {
      if (this.isListingLevel) {
        this.deleteListing();
      } else {
        this.deleteFee();
      }
    },

    async deleteFee() {
      this.isLoading = true;

      const response = await endpoints.deleteAdditionalFeeGeneralLevel({
        propertyId: this.propertyId,
        additionalFeeRuleId: this.data.id,
      });

      if (response) {
        this.$emit('action-success-delete');
      }

      this.closeModal();
      this.isLoading = false;
    },

    async deleteListing() {
      this.isLoading = true;

      const response = await endpoints.deleteAdditionalFeeListingLevel({
        propertyId: this.propertyId,
        additionalFeeRuleId: this.data.feeId,
        roomId: this.data.id,
      });

      if (response) {
        this.$emit('action-success-delete');
      }

      this.closeModal();
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./DeleteConfirmModal.scss"></style>
